<template>
    <div
        class="root with-overlay"
        :class="{'has-overlay': hasOverlay}"
    >
        <TheMenu v-show="!hideMenu" />
        <div
            v-if="!arePermissionsLoaded || !isLoaded"
            id="global-loader-wrapper"
            class="global-loader-wrapper"
        >
            <div class="global-loader">
                <Lottie
                    loop
                    :animation-data="loader"
                />
            </div>
        </div>
        <TheStoreSidebar />
        <RouterView
            v-if="arePermissionsLoaded"
            class="page-container"
            @success-login="onSuccessLogin"
        />
        <TheNotification />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import Lottie from 'lottie-web-vue';
import TheNotification from '../../components/TheNotification.vue';
import Loader from '../../../animations/loader.json';
import TheMenu from '../../components/Menu/TheMenu.vue';
import TheStoreSidebar from '../../components/Sidebar/TheStoreSidebar.vue';
import Getter from '../../store/classComponent/Getter';
import {
    globalSettingsModule,
    isAnonymousModule, permissionsModule,
    permissionsModule as pM,
    timezoneModule,
    userModule,
    syncModule,
    colorsModule,
    notificationModule,
} from '../../store';
import ROUTE_NAMES from '../../routes/routeNames';
import isUserSessionExpired from '../../../scripts/helpers/isUserSessionExpired';
import appApi from '../../../scripts/api/app/AppApi';
import ColorsApi from '../../../scripts/api/colors/ColorsApi';
import { HISTORY_POP_EVENT, HIDE_DROPDOWNS_EVENT } from '../../../scripts/constants/events';
import { addOnWowzaLowSpaceHandler } from '../../../scripts/api/wowza/SpaceCheckApi';
import { Notification } from '../../../scripts/helpers/Notification';
import { NotificationVariant } from '../../../scripts/helpers/Notification/Notification';
import { Variant } from '../../components/Elements/Button/Variant';
import { setTheme } from '../../../scripts/mixins/themeManager';
import { addOnUserLogoutSoonHandler } from '../../../scripts/api/user/userApi';
import { LoginResponse } from '../../../scripts/api/login/LoginApi';
import { allPermissions } from '../../../scripts/test/mocks/allPermissions';

@Options({
    components: {
        TheMenu,
        TheStoreSidebar,
        Lottie,
        TheNotification,
    },
})
export default class AppPage extends Vue {
    public readonly loader: Record<string, unknown> = Loader;

    private readonly CHECK_SESSION_INTERVAL_IN_MILLISECONDS = 60000;

    private checkUserSessionTimer: ReturnType<typeof setTimeout> | null = null;

    @Getter(['isLoaded'])
    public readonly isLoaded!: boolean;

    @Getter(['hasOverlay'])
    public readonly hasOverlay!: boolean;

    public created(): void {
        this.loadUserDetails();
        this.loadColors();
        this.subscribeToLowServerSpace();
        this.subscribeToUserLogoutSoon();

        if (!isAnonymousModule.isAnonymous) {
            this.enableUserSessionValidation();
        }

        this.addBackForwardButtonsRootEvent();
    }

    public get arePermissionsLoaded(): boolean {
        return (userModule.arePermissionsSet && pM.areAllPermissionsSet)
            || isAnonymousModule.isAnonymous;
    }

    public beforeDestroy(): void {
        this.disableUserSessionValidation();

        syncModule.unconfigureSync();
    }

    protected subscribeToLowServerSpace(): void {
        if (userModule.hasPermission(allPermissions.general)) {
            addOnWowzaLowSpaceHandler(({ text, title }) => {
                const notification = new Notification({
                    title,
                    text,
                    icon: null,
                    variant: NotificationVariant.TITLE_WITH_MESSAGE,
                    withCancel: true,
                    cancelText: 'Got it',
                    cancelVariant: Variant.DARK,
                });
                notificationModule.promptUser(notification);
            });
        }
    }

    protected subscribeToUserLogoutSoon(): void {
        addOnUserLogoutSoonHandler(({ text, title }) => {
            const notification = new Notification({
                title,
                text,
                icon: 'LockIcon',
                variant: NotificationVariant.TITLE_WITH_MESSAGE,
                withCancel: true,
                cancelText: 'Got it',
                cancelVariant: Variant.DARK,
            });

            notificationModule.promptUser(notification);
        });
    }

    protected async loadUserDetails(): Promise<void> {
        try {
            const details = await appApi.getUserDetails();
            userModule.setUserDetails(details);
            await setTheme(userModule.theme);
        } catch (e) {
            userModule.reset();
        }
    }

    protected async loadColors(): Promise<void> {
        const { colors, defaultColor } = await ColorsApi.getColors();
        colorsModule.setColors(colors);
        colorsModule.setDefaultColor(defaultColor);
    }

    private addBackForwardButtonsRootEvent(): void {
        // to prevent false positive please don't use hashes for links (e.g. /m/test#some1)
        // in components that listen to this event
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.onpopstate = (event: PopStateEvent) => this.$root.$emit(HISTORY_POP_EVENT, event);
    }

    protected async enableUserSessionValidation(): Promise<void> {
        if (this.checkUserSessionTimer) {
            return;
        }

        const callback = () => this.checkUserSession();
        this.checkUserSessionTimer = setInterval(
            callback,
            this.CHECK_SESSION_INTERVAL_IN_MILLISECONDS,
        );
    }

    protected disableUserSessionValidation(): void {
        if (!this.checkUserSessionTimer) {
            return;
        }

        clearInterval(this.checkUserSessionTimer);
    }

    public async loadPermissions(): Promise<void> {
        const { all, user } = await appApi.getPermissions();

        permissionsModule.setAllPermissions(all);
        userModule.setPermissions(user);
    }

    private async checkUserSession(): Promise<void> {
        const isLoginPage = this.$route.name === ROUTE_NAMES.LOGIN_MAIN;
        const isAnonymousPage = isAnonymousModule.isAnonymous;
        if (isLoginPage || isAnonymousPage) {
            return;
        }

        if (await isUserSessionExpired()) {
            localStorage.clear();
            userModule.reset();
            await this.$router.push({ name: ROUTE_NAMES.LOGIN_MAIN });
        }
    }

    public async onSuccessLogin(response: LoginResponse): Promise<void> {
        await this.loadUserDetails();

        if (!isAnonymousModule.isAnonymous) {
            await this.loadPermissions();
            await this.enableUserSessionValidation();
        }

        if (userModule.isPasswordExpired) {
            await this.$router.push({ name: ROUTE_NAMES.USER_SETTINGS });
        }

        if (typeof response.redirect !== 'string') {
            return;
        }

        window.location.href = response.redirect as string;
    }

    public get hideMenu(): boolean {
        if (this.$route.name === undefined) {
            return true;
        }

        return Boolean(this.$route.meta?.hideMenu);
    }
}

</script>

<style lang="scss">
@import '../../../styles/abstracts/z-indexes';

/* stylelint-disable selector-max-id */
#app {
    height: 100%;
}

.root {
    display: flex;

    flex-direction: row;
    flex-grow: 1;

    align-items: stretch;
    justify-content: stretch;

    width: 100%;
    height: 100%;
}

.page-container {
    display: flex;

    flex-grow: 1;

    min-height: 100%;

    background-color: var(--theme-color-surface-background);
}

.global-loader-wrapper {
    position: fixed;
    top: 0;

    z-index: $loader-z-index;

    display: flex;

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 100%;

    background: #000;
    opacity: 0.6;
}

.global-loader {
    width: 7rem;
}
</style>
