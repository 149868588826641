// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createApp, configureCompat } from 'vue';
import Clipboard from 'v-clipboard';
import { ToastPlugin } from 'bootstrap-vue';
import $ from 'jquery';
import App from './App.vue';
import router from './router';
import store, {
    globalSettingsModule, permissionsModule, syncModule, timezoneModule, userModule,
} from './store';
import '../styles/main.scss';
import '../scripts/class-component-hooks';
import AppApi from '../scripts/api/app/AppApi';
import { setTheme } from '../scripts/mixins/themeManager';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.jQuery = $;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.$ = $;

const loadGlobalSettings = async () => {
    try {
        const {
            dateFormat,
            streamingMethod,
            commentOverlaySize,
            time,
            sync,
        } = await AppApi.getGlobalSettings();

        globalSettingsModule.setDateFormatByName(dateFormat);
        globalSettingsModule.setStreamingMethod(streamingMethod);
        globalSettingsModule.setCommentOverlayFontSize(commentOverlaySize);

        timezoneModule.configureTime(time);
        syncModule.configureSync(sync);
    } catch (e) {
        console.error('Failed to load global settings');
    }
};

const loadPermissions = async () => {
    try {
        const { all, user } = await AppApi.getPermissions();
        permissionsModule.setAllPermissions(all);
        userModule.setPermissions(user);
    } catch (e) {
        console.error('Failed to load permissions:', e);
    }
};

const loadInitialData = async (): Promise<void> => {
    const results = await Promise.allSettled([
        loadGlobalSettings(),
        loadPermissions(),
    ]);

    results.forEach((result) => {
        if (result.status === 'rejected') {
            console.error('Error during initial data loading:', result.reason);
        }
    });
};

let app;

loadInitialData().then(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    app = createApp(App);

    app.use(router);
    app.use(store);

    app.use(Clipboard);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    app.use(ToastPlugin);

    configureCompat({
        MODE: 2,
    });

    app.mount('#app');

    setTheme(userModule.theme);
});
